import axios from "axios";
import { useEffect, useState } from "react";

const BACKEND = 'https://strapi.bemersem.uber.space';
function App() {
  const [gameKey, setGameKey] = useState('redirected');
  const [data, setData] = useState(null);
  useEffect(() => {
    const job = async () => { 
      const result = await axios.get(`${BACKEND}/game-data`);
      const redirected = result.data.find(a => a.key === gameKey);
      const data = { ...redirected.data };
      data.maxDistance += 1;
      await axios.put(`${BACKEND}/game-data/${redirected.id}`, {
        data
      });      
      setData(JSON.stringify(data))
    };
    job();
  }, [gameKey]);
  return (
    <>
      <div>DATA</div>
      {data}
    </>
  );
}

export default App;
